import React from 'react'
import t1 from './img/nissan.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Cars = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center  max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>55% sold</p>
    </div>
    </div>
  </div>
); 
const car  = [
  {
    id: 1,
    image: {t1},
    name: "Nissan Altima",
    details: "A stylish mid-size sedan with advanced technology and great fuel efficiency.",
    amount: "24000",
  },
  {
    id: 2,
    image: {t1},
    name: "Nissan Maxima",
    details: "A premium full-size sedan offering performance and luxury.",
    amount: "37000",
  },
  {
    id: 3,
    image: {t1},
    name: "Nissan Rogue",
    details: "A compact SUV with a spacious interior and advanced safety features.",
    amount: "28000",
  },
  {
    id: 4,
    image: {t1},
    name: "Nissan Sentra",
    details: "A compact sedan known for its affordability and comfort.",
    amount: "21000",
  },
  {
    id: 5,
    image: {t1},
    name: "Nissan Pathfinder",
    details: "A versatile mid-size SUV with powerful performance and spacious seating.",
    amount: "34000",
  },
  {
    id: 6,
    image: {t1},
    name: "Nissan Frontier",
    details: "A rugged mid-size pickup truck built for tough jobs and off-road capability.",
    amount: "29000",
  },
  {
    id: 7,
    image: {t1},
    name: "Nissan Titan",
    details: "A full-size pickup truck offering a powerful V8 engine and towing capacity.",
    amount: "40000",
  },
  {
    id: 8,
    image: {t1},
    name: "Nissan Leaf",
    details: "An all-electric compact car with zero emissions and advanced technology.",
    amount: "28000",
  },
  {
    id: 9,
    image: {t1},
    name: "Nissan Murano",
    details: "A mid-size crossover with a stylish design and comfortable ride.",
    amount: "33000",
  },
  {
    id: 10,
    image: {t1},
    name: "Nissan Armada",
    details: "A full-size SUV with powerful performance and spacious seating for up to 8 passengers.",
    amount: "50000",
  },
  {
    id: 11,
    image: {t1},
    name: "Nissan Versa",
    details: "An affordable subcompact sedan offering great fuel economy.",
    amount: "16000",
  },
  {
    id: 12,
    image: {t1},
    name: "Nissan Kicks",
    details: "A subcompact crossover with agile handling and customizable interior.",
    amount: "21000",
  },
  {
    id: 13,
    image: {t1},
    name: "Nissan Juke",
    details: "A compact crossover with bold design and turbocharged performance.",
    amount: "23000",
  },
  {
    id: 14,
    image: {t1},
    name: "Nissan GT-R",
    details: "A high-performance sports car with a twin-turbo V6 engine and all-wheel drive.",
    amount: "113000",
  },
  {
    id: 15,
    image: {t1},
    name: "Nissan 370Z",
    details: "A powerful and affordable sports car with a sleek design.",
    amount: "45000",
  }
]
const Nissan = () => {
 
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {car.map((allcar) => (
          <Cars
            key={allcar.id}
            id={allcar.id}
            name={allcar.name}
            image={allcar.image.t1}
            details={allcar.details}
            amount={allcar.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Nissan