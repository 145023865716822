import React from 'react'
import t1 from './img/open2.jpg'
import t2 from './img/open4.jpg'
import t3 from './img/open6.jpg'
import t4 from './img/open8.jpg'
import t5 from './img/open12.jpeg'
import t6 from './img/open13.jpg'
import t7 from './img/open14.jpg'
import t8 from './img/t8.jpeg'
import t9 from './img/open4.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Houses = ({id, name, image, details, amount, newp}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <strike>£{amount}</strike>
    <p>New price: £{newp}</p>
    </div>
    </div>
  </div>
); 
const house  = [
      {
        id: 1,
        image: `${t2}`,
        name: "Victorian Townhouse",
        details: "4-bedroom Victorian-era home with original features, close to city center.",
        amount: "780,000",
        newp: "710, 000"
      },
      {
        id: 2,
        image: `${t3}`,
        name: "Contemporary Apartment",
        details: "1-bed apartment in a modern building, located near public transport.",
        amount: "410,000",
        newp: "310, 000"
      },
      {
        id: 3,
        image: `${t1}`,
        name: "Modern Bungalow",
        details: "2-bed bungalow with garden and parking. Ideal for small families.",
        amount: "520,000",
        newp: "470, 000"
      },
      {
        id: 4,
        image: `${t4}`,
        name: "Detached Family Home",
        details: "Spacious 5-bedroom house with large garden and double garage.",
        amount: "850,000",
        newp: "780, 000"
      },
      {
        id: 5,
        image: `${t5}`,
        name: "Cottage by the Sea",
        details: "Charming 3-bedroom cottage with stunning sea views, perfect for vacationers.",
        amount: "600,000",
        newp: "550, 000"
      },
      {
        id: 6,
        image: `${t6}`,
        name: "Penthouse Suite",
        details: "Luxury penthouse with panoramic views of the city, 3 bedrooms, and balcony.",
        amount: "1,200,000",
        newp: "1,100,000"
      },
      {
        id: 7,
        image: `${t8}`,
        name: "City Studio",
        details: "Compact studio apartment in the heart of the financial district.",
        amount: "350,000",
        newp: "250, 000"
      },
      {
        id: 8,
        image: `${t7}`,
        name: "Country Estate",
        details: "8-bedroom manor set on 10 acres of land, featuring a private lake.",
        amount: "4,500,000",
        newp: "4, 450, 000"
      },
      {
        id: 9,
        image: `${t9}`,
        name: "Georgian Terrace",
        details: "Elegant 4-bedroom terrace home with original fireplaces and high ceilings.",
        amount: "950,000",
        newp: "850, 000"
      },
]
const Pricered = () => {
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {house.map((allhouse) => (
          <Houses
            key={allhouse.id}
            id={allhouse.id}
            name={allhouse.name}
            image={allhouse.image}
            details={allhouse.details}
            amount={allhouse.amount}
            newp={allhouse.newp}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Pricered