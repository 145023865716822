import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import { Helmet } from 'react-helmet'
const Contact = () => {
  return (
    <div>
      <Navbar/>
      <Helmet>
        <title>Contact Us - Strongbox Express</title>
        <meta name="description" content="Get in touch with Strongbox Express for property management services." />
      </Helmet>
      <section className='h-[110vh] w-[100%] flex flex-col gap-[20px] items-center justify-center max-sm:h-full max-sm:p-[30px]'>
        <div className='flex flex-col items-center p-[10px]'>
          <p className='font-semibold text-[30px] p-4'>Contact Form</p>
          <p className='w-[30vw] text-center text-lg max-md:w-[70vw]'>Fill the field below and our admin will get in touch with you</p>
        </div>
        <form action="https://formspree.io/f/xwpkevgy" method="post" className='flex flex-col items-start justify-center gap-[25px]'>
          <div className='flex flex-col flex-start gap-[4px]'>
          <label>Name:</label>
          <input className='border-[1px] border-black outline-none p-[7px] w-[30vw] rounded-md max-sm:w-[60vw]' type="text" name="name" placeholder="Your name" required />
          </div>
          <div className='flex flex-col flex-start gap-[4px]'>
          <label>Email:</label>
          <input className='border-[1px] border-black outline-none p-[7px] w-[30vw] rounded-md max-sm:w-[60vw]' type="email" name="email" placeholder="Email adress" required />
          </div>
         <div className='flex flex-col flex-start gap-[4px]'>
          <label>Message:</label>
         <textarea  className='border-[1px] border-black outline-none p-[7px] w-[30vw] rounded-md h-[30vh] max-sm:w-[60vw]'name="message" placeholder="Message" required></textarea>
         </div>
          <button type="submit" className='bg-black text-white w-[30vw] max-sm:w-[60vw] p-[10px] rounded-md hover:bg-transparent hover:border-[1px] hover:border-black hover:text-black duration-[1s]'>Submit</button>
        </form>
      </section>
      <Footer/>
    </div>
  )
}

export default Contact