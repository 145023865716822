import React from 'react'
import t1 from './img/nmc2.jpg'
import t2 from './img/nmc3.jpg'
import t3 from './img/nmc4.jpg'
import t4 from './img/nmc5.jpeg'
import t5 from './img/nmc7.jpg'
import t6 from './img/open13.jpg'
import t7 from './img/open14.jpg'
import t8 from './img/t8.jpeg'
import t9 from './img/open4.jpg'
import t10 from './img/t10.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Houses = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>70% sold</p>
    </div>
    </div>
  </div>
); 
const house  = [
    {
        id: 1,
        image: `${t1}`,
        name: "Postmark, Farringdon",
        details: "1 to 4-bedroom homes located in central London, with commercial space and new public areas.",
        amount: "995,000",
      },
      {
        id: 2,
        image: `${t2}`,
        name: "Chelsea Barracks, Belgravia",
        details: "Luxury estate offering 1 to 5-bedroom apartments in one of London's most prestigious areas.",
        amount: "3,300,000",
      },
      {
        id: 3,
        image: `${t3}`,
        name: "Barden Croft, Tonbridge",
        details: "2 to 5-bedroom homes in Kent's beautiful countryside, with energy-efficient features.",
        amount: "645,000",
      },
      {
        id: 4,
        image: `${t4}`,
        name: "Chelsea Waterfront, Lots Road",
        details: "Modern 1 to 4-bedroom apartments with access to high-end leisure facilities in London.",
        amount: "1,070,000",
      },
      {
        id: 5,
        image: `${t5}`,
        name: "Evergreen, Harringay Green Lanes",
        details: "Stylish homes in a vibrant North London community, surrounded by a tranquil garden square.",
        amount: "567,500",
      },
      {
        id: 6,
        image: `${t6}`,
        name: "Triptych Bankside, Southwark",
        details: "Luxurious 2 and 3-bedroom apartments in a mixed-use development, offering stunning views.",
        amount: "1,735,000",
      },
      {
        id: 7,
        image: `${t7}`,
        name: "W1 Place, Marylebone",
        details: "1 to 2-bedroom apartments with modern design in the heart of London’s West End.",
        amount: "1,966,000",
      },
      {
        id: 8,
        image: `${t8}`,
        name: "Scene 2, Walthamstow",
        details: "Contemporary 1 to 3-bedroom homes in East London, offering cutting-edge design and amenities.",
        amount: "375,000",
      },
      {
        id: 9,
        image: `${t9}`,
        name: "Parkhouse Street, Camberwell",
        details: "1 to 3-bedroom homes in a mixed-use development, offering views over Burgess Park.",
        amount: "POA",
      },
      {
        id: 10,
        image: `${t10}`,
        name: "Barking Riverside, Barking",
        details: "1 to 4-bedroom homes in a major new riverside community in East London, featuring schools and parks.",
        amount: "350,000",
      },
]
const Nmc = () => {
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {house.map((allhouse) => (
          <Houses
            key={allhouse.id}
            id={allhouse.id}
            name={allhouse.name}
            image={allhouse.image}
            details={allhouse.details}
            amount={allhouse.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Nmc