import React from 'react'
import t1 from './img/l2.jpg'
import t2 from './img/f2.jpg'
import t3 from './img/f3.jpg'
import t4 from './img/f4.jpg'
import t5 from './img/open12.jpeg'
import t6 from './img/f6.jpg'
import t7 from './img/f7.jpg'
import t8 from './img/f8.jpg'
import t9 from './img/f9.jpg'
import t10 from './img/f10.jpg'
import t11 from './img/f11.jpg'
import t12 from './img/f12.jpg'
import t13 from './img/f13.jpg'
import t14 from './img/t14.jpg'
import t15 from './img/f15.jpeg'
import Navbar from './Navbar';
import Footer from './Footer';
const Houses = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>for sale</p>
    </div>
    </div>
  </div>
); 
const house  = [
    {
        id: 1,
        image: `${t1}`,
        name: "Foreclosed Modern Bungalow",
        details: "2-bed bungalow with garden, requires minor renovation. Located in a quiet suburban area.",
        amount: "380,000",
      },
      {
        id: 2,
        image: `${t2}`,
        name: "Foreclosed Victorian Townhouse",
        details: "4-bedroom Victorian-era home needing repairs, situated near city center.",
        amount: "500,000",
      },
      {
        id: 3,
        image: `${t3}`,
        name: "Foreclosed Contemporary Apartment",
        details: "1-bed apartment in a modern complex, requires refurbishment, close to public transport.",
        amount: "320,000",
      },
      {
        id: 4,
        image: `${t4}`,
        name: "Foreclosed Detached Family Home",
        details: "Spacious 5-bedroom house with a large garden, needs structural work.",
        amount: "600,000",
      },
      {
        id: 5,
        image: `${t5}`,
        name: "Foreclosed Cottage by the Sea",
        details: "3-bedroom cottage with sea views, requires some renovation work, ideal for vacation home.",
        amount: "450,000",
      },
      {
        id: 6,
        image: `${t6}`,
        name: "Foreclosed Penthouse Suite",
        details: "Luxury penthouse with city views, partially finished, requires interior completion.",
        amount: "850,000",
      },
      {
        id: 7,
        image: `${t7}`,
        name: "Foreclosed Country Estate",
        details: "8-bedroom manor set on 10 acres, partially damaged, needs extensive renovation.",
        amount: "3,200,000",
      },
      {
        id: 8,
        image: `${t8}`,
        name: "Foreclosed City Studio",
        details: "Compact studio apartment in a prime location, requires refurbishment.",
        amount: "250,000",
      },
      {
        id: 9,
        image: `${t9}`,
        name: "Foreclosed Georgian Terrace",
        details: "4-bedroom terrace home with high ceilings, needs modernization.",
        amount: "700,000",
      },
      {
        id: 10,
        image: `${t10}`,
        name: "Foreclosed Suburban Semi-detached",
        details: "3-bedroom semi-detached house in need of renovation, garden space included.",
        amount: "420,000",
      },
      {
        id: 11,
        image: `${t11}`,
        name: "Foreclosed Loft Conversion",
        details: "2-bedroom loft conversion with exposed brick, needs repairs.",
        amount: "540,000",
      },
      {
        id: 12,
        image: `${t12}`,
        name: "Foreclosed Modern Duplex",
        details: "2-story duplex apartment requiring refurbishment, located in a developing area.",
        amount: "480,000",
      },
      {
        id: 13,
        image: `${t13}`,
        name: "Foreclosed Riverside Flat",
        details: "1-bedroom flat with riverside views, needs renovation, private balcony included.",
        amount: "320,000",
      },
      {
        id: 14,
        image: `${t14}`,
        name: "Foreclosed Victorian Semi-detached",
        details: "3-bedroom semi-detached house, requires significant repairs, retains original features.",
        amount: "520,000",
      },
      {
        id: 15,
        image: `${t15}`,
        name: "Foreclosed Countryside Cottage",
        details: "2-bedroom stone cottage in a rural area, in need of renovation, perfect for weekend getaways.",
        amount: "360,000",
      }
]
const Fore = () => {
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {house.map((allhouse) => (
          <Houses
            key={allhouse.id}
            id={allhouse.id}
            name={allhouse.name}
            image={allhouse.image}
            details={allhouse.details}
            amount={allhouse.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Fore