import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Feed = () => {
  return (
    <div>
        <Navbar/>
        <section className='flex flex-col items-center justify-center p-[50px]'>
            <form action="https://formspree.io/f/xwpkevgy" method='post'>
                <p className='flex items-center justify-center text-lg'>What is your feedback regarding</p>
          <main className='flex flex-col gap-[30px] '>
          <div className='flex flex-col flex-start gap-[4px]'>
                <label>Name:</label>
                <input className='border-[1px] border-black outline-none p-[7px] w-[30vw] rounded-md max-sm:w-[60vw]' type="text" name="name" placeholder="Your name" required />
            </div>
                <div className='flex flex-col flex-start gap-[4px]'>
                <label>Email:</label>
                <input className='border-[1px] border-black outline-none p-[7px] w-[30vw] rounded-md max-sm:w-[60vw]' type="email" name="email" placeholder="Email adress" required />
            </div>
            <div className='border-[1px] border-black outline-none p-[7px] w-[30vw] rounded-md max-sm:w-[60vw]'>
                <label>Feedback</label>
                <select required name="message">
                    <option  value="">Select an option</option>
                    <option  value="Report technical issues">Report technical issues</option>
                    <option value="Improvement suggestion">Improvement suggestion</option>
                    <option value="Listing informaiton feedback">Listing informaiton feedback</option>
                    <option value="Genaral inquires">Genaral inquires</option>
                    <option value="Future suggestion">Future suggestion</option>
                </select>
            </div>
            <button type="submit" className='bg-black text-white w-[30vw] max-sm:w-[60vw] p-[10px] rounded-md hover:bg-transparent hover:border-[1px] hover:border-black hover:text-black duration-[1s]'>Submit</button>
          </main>
            </form>
        </section>
        <Footer/>
    </div>
  )
}

export default Feed