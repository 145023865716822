import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Tp = () => {
  return (
    <div>
        <Navbar/>
            <section className='flex flex-col gap-[30px] items-center justify-center p-[70px] text-lg'>
                <div>
                    <div>
                    <h1 className='text-2xl font-semibold'>Privacy Policy</h1>
                    <p className='w-[50vw] max-sm:w-[70vw]'>At Strongbox, we respect your privacy and are committed to protecting the personal information that you provide to us while using our services.</p>
                    </div>
              <div>
              <p className='font-semibold'>1. Information We Collect</p>
              <ul className='list-disc p-[20px]'>
                <li className='w-[50vw] max-sm:w-[70vw]'> Property Details: Information related to properties listed on the platform, including location, price, images, and descriptions.</li>
                <li className='w-[50vw] max-sm:w-[70vw]'>Business Information: Contact details of real estate agents, property developers, or companies managing property listings.</li>
               <li className='w-[50vw] max-sm:w-[70vw]'> Analytics Data: We may collect non-personally identifiable information such as browser type, device information, and page interactions to improve our services.</li>
              </ul>
              </div>
                </div>
              <div>
                <p  className='font-semibold'>2. How We Use Your Information</p>
               <ul  className='list-disc p-[20px]'>
               <li className='w-[50vw] max-sm:w-[70vw]'>Property Listings: To display and manage listings on the platform.</li>
                <li className='w-[50vw] max-sm:w-[70vw]'>Communication: To send updates regarding properties, market trends, or inquiries about listings.</li>
                <li className='w-[50vw] max-sm:w-[70vw]'>Improve Services: To analyze usage patterns, troubleshoot issues, and improve the platform's functionality.</li>
               </ul>
              </div>
              <div>
                <p className='font-semibold'>3. Data Sharing</p>
               <ul  className='list-disc p-[20px]'>
              <li className='w-[50vw] max-sm:w-[70vw]'> Third Parties: We may share information with third-party service providers to assist with property promotion, marketing, or analytics, but we do not sell personal data to any third-party for marketing purposes.</li>
               <li className='w-[50vw] max-sm:w-[70vw]'>Legal Requirements: We may disclose information if required by law or to comply with legal processes.</li>
               </ul>
              </div>
              <div>
              <p className='font-semibold'>4. Data Security</p>
              <ul className='list-disc p-[20px]'>
                <li className='w-[50vw] max-sm:w-[70vw]'>We implement industry-standard security measures to protect your information from unauthorized access, loss, or alteration.</li>
              </ul>
              </div>
              <div>
              <p className='font-semibold'>5. Data Retention</p>
              <ul className='list-disc p-[20px]'>
                <li  className='w-[50vw] max-sm:w-[70vw]'>Strongbox retains property-related data as long as necessary to provide our services and comply with legal obligations. Contact data for agents and developers may be retained for business communication purposes.</li>
              </ul>
              </div>
              <div>
                <p className='font-semibold'>6. International Data Transfers</p>
                <ul className='list-disc p-[20px]'>
                    <li className='w-[50vw] max-sm:w-[70vw]'>If you are accessing Strongbox from outside the UK, your data may be transferred internationally. By using our services, you consent to such transfers in accordance with this Privacy Policy.</li>
                </ul>
              </div>
              <div>
              <p  className='font-semibold'>7. Changes to This Privacy Policy</p>
              <ul className='list-disc p-[20px]'>
                <li className='w-[50vw] max-sm:w-[70vw]'>Strongbox reserves the right to modify this Privacy Policy at any time. Continued use of the platform constitutes acceptance of the updated policy.</li>
              </ul>
              </div>
              <div>
              <p className='font-semibold'>8. Contact Us</p>
            <ul  className='list-disc p-[20px]'>
                <li className='w-[50vw] max-sm:w-[70vw]'>For any questions or concerns about this Privacy Policy, please contact us at <a className='text-blue-700' href="/contact">contact us now</a>.</li>
            </ul>
              </div>
            </section>
        <Footer/>
    </div>
  )
}

export default Tp