import React from 'react'
import t1 from './img/ford.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Cars = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>75% sold</p>
    </div>
    </div>
  </div>
); 
const car  = [
    {
        id: 1,
        image: {t1},
        name: "Ford F-150",
        details: "A full-size pickup truck known for its durability, performance, and towing capacity.",
        amount: "45000",
      },
      {
        id: 2,
        image: {t1},
        name: "Ford Mustang",
        details: "A legendary sports car offering iconic style and powerful performance.",
        amount: "55000",
      },
      {
        id: 3,
        image: {t1},
        name: "Ford Explorer",
        details: "A mid-size SUV offering spacious seating and advanced safety features.",
        amount: "52000",
      },
      {
        id: 4,
        image: {t1},
        name: "Ford Escape",
        details: "A compact SUV with great fuel efficiency and modern technology.",
        amount: "32000",
      },
      {
        id: 5,
        image: {t1},
        name: "Ford Bronco",
        details: "A rugged off-road SUV built for adventure with a retro-inspired design.",
        amount: "42000",
      },
      {
        id: 6,
        image: {t1},
        name: "Ford Expedition",
        details: "A full-size SUV offering massive interior space and powerful performance.",
        amount: "68000",
      },
      {
        id: 7,
        image: {t1},
        name: "Ford Maverick",
        details: "A compact pickup truck offering great fuel efficiency and versatility.",
        amount: "25000",
      },
      {
        id: 8,
        image: {t1},
        name: "Ford Ranger",
        details: "A mid-size pickup truck with impressive towing capacity and off-road capability.",
        amount: "37000",
      },
      {
        id: 9,
        image: {t1},
        name: "Ford Edge",
        details: "A mid-size SUV offering a sporty design and advanced tech features.",
        amount: "41000",
      },
      {
        id: 10,
        image: {t1},
        name: "Ford Fusion",
        details: "A mid-size sedan with a sleek design and hybrid options for fuel efficiency.",
        amount: "29000",
      },
      {
        id: 11,
        image: {t1},
        name: "Ford EcoSport",
        details: "A compact SUV with a stylish design and nimble performance.",
        amount: "24000",
      },
      {
        id: 12,
        image: {t1},
        name: "Ford Transit",
        details: "A full-size commercial van offering spacious cargo capacity and customizable options.",
        amount: "43000",
      },
      {
        id: 13,
        image: {t1},
        name: "Ford Mach-E",
        details: "An all-electric SUV with impressive range and a futuristic design.",
        amount: "58000",
      },
      {
        id: 14,
        image: {t1},
        name: "Ford Taurus",
        details: "A large sedan offering a comfortable ride and advanced safety features.",
        amount: "35000",
      },
      {
        id: 15,
        image: {t1},
        name: "Ford Super Duty",
        details: "A heavy-duty truck built for extreme towing and hauling.",
        amount: "75000",
      }
]
const Ford = () => {
 
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {car.map((allcar) => (
          <Cars
            key={allcar.id}
            id={allcar.id}
            name={allcar.name}
            image={allcar.image.t1}
            details={allcar.details}
            amount={allcar.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Ford