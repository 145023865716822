import React from 'react'
import t1 from './img/lexus.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Cars = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>90% sold</p>
    </div>
    </div>
  </div>
); 
const car  = [
    {
        id: 1,
        image: {t1},
        name: "Lexus ES",
        details: "A luxury sedan known for its smooth ride and upscale interior.",
        amount: "41000",
      },
      {
        id: 2,
        image: {t1},
        name: "Lexus RX",
        details: "A mid-size luxury SUV with a comfortable interior and advanced safety features.",
        amount: "47000",
      },
      {
        id: 3,
        image: {t1},
        name: "Lexus NX",
        details: "A compact luxury SUV combining sporty design with efficiency.",
        amount: "39000",
      },
      {
        id: 4,
        image: {t1},
        name: "Lexus LX",
        details: "A full-size luxury SUV offering off-road capability and premium features.",
        amount: "90000",
      },
      {
        id: 5,
        image: {t1},
        name: "Lexus IS",
        details: "A compact luxury sedan with a sporty demeanor and powerful engines.",
        amount: "40000",
      },
      {
        id: 6,
        image: {t1},
        name: "Lexus GS",
        details: "A mid-size luxury sedan with dynamic performance and elegant styling.",
        amount: "60000",
      },
      {
        id: 7,
        image: {t1},
        name: "Lexus LC",
        details: "A luxury coupe with a striking design and high-performance hybrid engine.",
        amount: "95000",
      },
      {
        id: 8,
        image: {t1},
        name: "Lexus RC",
        details: "A stylish luxury coupe offering thrilling performance and a refined interior.",
        amount: "43000",
      },
      {
        id: 9,
        image: {t1},
        name: "Lexus GX",
        details: "A mid-size luxury SUV with rugged off-road capability and a luxurious cabin.",
        amount: "85000",
      },
      {
        id: 10,
        image: {t1},
        name: "Lexus CT",
        details: "A compact luxury hatchback with hybrid efficiency and sporty handling.",
        amount: "30000",
      },
      {
        id: 11,
        image: {t1},
        name: "Lexus LS",
        details: "The flagship luxury sedan featuring unparalleled comfort and advanced technology.",
        amount: "95000",
      },
      {
        id: 12,
        image: {t1},
        name: "Lexus RX Hybrid",
        details: "A hybrid version of the popular RX SUV with eco-friendly performance.",
        amount: "52000",
      },
      {
        id: 13,
        image: {t1},
        name: "Lexus NX Hybrid",
        details: "A hybrid compact SUV combining efficiency with luxury.",
        amount: "41000",
      },
      {
        id: 14,
        image: {t1},
        name: "Lexus LC Hybrid",
        details: "An eco-friendly luxury coupe offering performance and style.",
        amount: "100000",
      },
      {
        id: 15,
        image: {t1},
        name: "Lexus IS F Sport",
        details: "A performance-oriented version of the IS sedan with sporty enhancements.",
        amount: "46000",
      },
      {
        id: 16,
        image: {t1},
        name: "Lexus ES Hybrid",
        details: "A luxury hybrid sedan providing comfort and fuel efficiency.",
        amount: "47000",
      },
      {
        id: 17,
        image: {t1},
        name: "Lexus GX Hybrid",
        details: "A hybrid version of the rugged GX SUV, combining luxury with off-road capability.",
        amount: "91000",
      },
      {
        id: 18,
        image: {t1},
        name: "Lexus LS Hybrid",
        details: "A hybrid flagship sedan that emphasizes luxury and performance.",
        amount: "97000",
      },
      {
        id: 19,
        image: {t1},
        name: "Lexus RX 450h",
        details: "A hybrid luxury SUV combining style with environmental responsibility.",
        amount: "57000",
      },
      {
        id: 20,
        image: {t1},
        name: "Lexus NX 300h",
        details: "A compact luxury hybrid SUV offering versatility and efficiency.",
        amount: "43000",
      },
      {
        id: 21,
        image: {t1},
        name: "Lexus LC 500h",
        details: "A stunning hybrid coupe delivering exceptional performance and style.",
        amount: "102000",
      },
      {
        id: 22,
        image: {t1},
        name: "Lexus RX 350",
        details: "A popular luxury SUV offering a balance of comfort and performance.",
        amount: "49000",
      },
      {
        id: 23,
        image: {t1},
        name: "Lexus ES 300h",
        details: "A hybrid luxury sedan combining comfort with fuel efficiency.",
        amount: "48000",
      },
      {
        id: 24,
        image: {t1},
        name: "Lexus IS 300",
        details: "A stylish compact sedan with a focus on performance and luxury.",
        amount: "39000",
      },
      {
        id: 25,
        image: {t1},
        name: "Lexus UX",
        details: "A compact luxury crossover with modern styling and agile handling.",
        amount: "35000",
      },
      {
        id: 26,
        image: {t1},
        name: "Lexus RX 500h",
        details: "A powerful hybrid SUV that offers performance and efficiency.",
        amount: "62000",
      },
      {
        id: 27,
        image: {t1},
        name: "Lexus NX 350",
        details: "A compact luxury SUV offering advanced technology and comfort.",
        amount: "46000",
      },
      {
        id: 28,
        image: {t1},
        name: "Lexus LX 600",
        details: "A full-size luxury SUV designed for performance on and off the road.",
        amount: "100000",
      },
      {
        id: 29,
        image: {t1},
        name: "Lexus LC 500",
        details: "A luxury coupe with a powerful V8 engine and stunning design.",
        amount: "93000",
      },
      {
        id: 30,
        image: {t1},
        name: "Lexus IS 500 F SPORT",
        details: "A performance sedan that combines luxury with impressive horsepower.",
        amount: "60000",
      }
    
]
const Lexus = () => {
 
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {car.map((allcar) => (
          <Cars
            key={allcar.id}
            id={allcar.id}
            name={allcar.name}
            image={allcar.image.t1}
            details={allcar.details}
            amount={allcar.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Lexus