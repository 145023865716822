import React from 'react'
const Browse = () => {
  return (
    <div className='p-[50px]'>
        <section>
            <p className='font-bold text-[30px] max-sm:text-center max-sm:font-semibold'>Browse homes in Birmingham</p>
            <main className='grid md:grid-cols-4 pt-[20px]  text-white gap-[20px] text-lg sm:grid-cols-2 max-sm:grid-cols-1 max-sm:items-center max-sm:flex max-sm:flex-col max-sm:justify-center'>
                <a href="/newlistings">
                <div className='land flex items-start justify-between p-[10px]'>
                    <p className='font-semibold'>New listings</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>15</p>
                </div>
                </a>
                <a href="/pricereduced">
                <div className='red flex items-start justify-between p-[10px]'>
                    <p  className='font-semibold'>Price reduced</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>9</p>
                </div>
                </a>
               <a href="/openhouse">
               <div className='oh flex items-start justify-between p-[10px]'>
                    <p  className='font-semibold'>Open house</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>14</p>
                </div>
               </a>
               <a href="/recently-sold">
               <div className='sh flex items-start justify-between p-[10px]'>
                    <p  className='font-semibold'>Recently sold</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>9</p>
                </div>  
               </a>
                {/* second row */}
                <a href="/new-contructions">
                <div className='con flex items-start justify-between p-[10px]'>
                    <p className='font-semibold'>New constructions</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>7</p>
                </div>
                </a>
              <a href="/new-home-communities">
              <div className='nh flex items-start justify-between p-[10px]'>
                    <p className='font-semibold'>New Home Communities</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>10</p>
                </div>
              </a>
                <a href="/lands">
                <div className='land flex items-start justify-between p-[10px]'>
                    <p className='font-semibold'>Land</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>12</p>
                </div>
                </a>
                <a href="/foreclosures">
                <div className='oh flex items-start justify-between p-[10px]'>
                    <p className='font-semibold'>Foreclosures</p>
                    <p className='bg-white text-black p-1 w-[3vw] text-center rounded-[20em] max-sm:w-[12vw]'>15</p>
                </div>
                </a>
            </main>
        </section>
    </div>
  )
}

export default Browse