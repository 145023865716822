import React from 'react'
import t1 from './img/bmw.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Cars = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>85% sold</p>
    </div>
    </div>
  </div>
); 
const car  = [
    {
        id: 1,
        image: {t1},
        name: "BMW 3 Series",
        details: "A compact luxury sedan offering excellent driving dynamics and advanced technology.",
        amount: "45000",
      },
      {
        id: 2,
        image: {t1},
        name: "BMW 5 Series",
        details: "A mid-size luxury sedan with cutting-edge features and powerful engine options.",
        amount: "60000",
      },
      {
        id: 3,
        image: {t1},
        name: "BMW 7 Series",
        details: "A full-size luxury sedan offering top-tier comfort, performance, and innovation.",
        amount: "95000",
      },
      {
        id: 4,
        image: {t1},
        name: "BMW X3",
        details: "A compact luxury SUV with versatile performance and a premium interior.",
        amount: "53000",
      },
      {
        id: 5,
        image: {t1},
        name: "BMW X5",
        details: "A mid-size luxury SUV combining power, style, and advanced features.",
        amount: "70000",
      },
      {
        id: 6,
        image: {t1},
        name: "BMW X7",
        details: "A full-size luxury SUV offering spacious three-row seating and a powerful engine.",
        amount: "95000",
      },
      {
        id: 7,
        image: {t1},
        name: "BMW Z4",
        details: "A sporty roadster with a sleek design and agile performance.",
        amount: "65000",
      },
      {
        id: 8,
        image: {t1},
        name: "BMW M3",
        details: "A high-performance variant of the 3 Series, designed for speed and precision.",
        amount: "72000",
      },
      {
        id: 9,
        image: {t1},
        name: "BMW i4",
        details: "An all-electric luxury sedan combining sustainability with driving pleasure.",
        amount: "66000",
      },
      {
        id: 10,
        image: {t1},
        name: "BMW iX",
        details: "An all-electric luxury SUV featuring cutting-edge technology and powerful performance.",
        amount: "85000",
      }
]
const Bmw = () => {
 
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {car.map((allcar) => (
          <Cars
            key={allcar.id}
            id={allcar.id}
            name={allcar.name}
            image={allcar.image.t1}
            details={allcar.details}
            amount={allcar.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Bmw