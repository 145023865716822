import './App.css';
import Homepage from './components/homepage/homepage';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from './components/landingpage/Contact';
import Toylist from './components/landingpage/Toylist';
import Nissan from './components/landingpage/Nissan';
import Bmw from './components/landingpage/Bmw';
import Ford from './components/landingpage/Ford';
import Benz from './components/landingpage/Benz';
import Lexus from './components/landingpage/Lexus';
import About from './components/landingpage/About';
import Review from './components/landingpage/Review';
import Newlist from './components/landingpage/Newlist';
import Pricered from './components/landingpage/Pricered';
import Openh from './components/landingpage/Openh';
import Newcon from './components/landingpage/Newcon';
import Nmc from './components/landingpage/Nmc';
import Land from './components/landingpage/Land';
import Fore from './components/landingpage/Fore';
import Rs from './components/landingpage/Rs';
import Tp from './components/landingpage/Tp';
import Feed from './components/landingpage/Feed';
function App() {
  return (
    <div className="App">
      <Router>
       <Routes>
          <Route path='/' element={<Homepage/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/review' element={<Review/>}/>
          <Route path='/feedback' element={<Feed/>}/>
          <Route path='/terms&privacy' element={<Tp/>}/>
          <Route path='/toyota' element={<Toylist/>}/>
          <Route path='/nissan' element={<Nissan/>}/>
          <Route path='/bmw' element={<Bmw/>}/>
          <Route path='/ford' element={<Ford/>}/>
          <Route path='/benz' element={<Benz/>}/>
          <Route path='/lexus' element={<Lexus/>}/>
          <Route path='/newlistings' element={<Newlist/>}/>
          <Route path='/pricereduced' element={<Pricered/>}/>
          <Route path='/openhouse' element={<Openh/>}/>
          <Route path='/new-contructions' element={<Newcon/>}/>
          <Route path='/new-home-communities' element={<Nmc/>}/>
          <Route path='/lands' element={<Land/>}/>
          <Route path='/foreclosures' element={<Fore/>}/>
          <Route path='/recently-sold' element={<Rs/>}/>
       </ Routes>
      </Router>
    </div>
  );
}

export default App;
