import {React, useState} from 'react'
import harm from './img/harm.png'
const Navbar = () => {
  const [menu, setMenu] = useState(false)
  const togglemenu = () => {
        setMenu(!menu)
  }
  return (
    <div className='sticky top-0'>
        <nav className='bg-white flex items-center justify-around text-lg p-[20px] max-sm:justify-between'>
            <a href="/"><div className='font-semibold text-center'>Strongbox Express</div></a>
            <ul className='flex items-center justify-center gap-[40px] max-sm:hidden'>
                <a href="/"><li className='hover:border-b-2 border-black cursor-pointer text-center'>Home</li></a>
                <a href="/about"><li className='hover:border-b-2 border-black cursor-pointer text-center'>About us</li></a>
                <a href="/terms&privacy"><li className='hover:border-b-2 border-black cursor-pointer text-center'>Terms & privacy</li></a>
                <a href="/review"><li className='hover:border-b-2 border-black cursor-pointer text-center'>Reviews</li></a>
            </ul>
            <a href="/contact"><button className='text-white bg-black p-[4px] w-[8vw] rounded-lg max-sm:w-[20vw] max-sm:hidden text-center'>Contact</button></a>
            <img onClick={togglemenu} className='hidden max-sm:flex' draggable="false" width={35} src={harm} alt="" />
            {menu&&
            <ul className='flex flex-col items-center justify-center absolute top-16 right-0 left-0  bg-white p-[20px] gap-4'>
            <a href="/"><li className='hover:border-b-2 border-black cursor-pointer'>Home</li></a>
            <a href="/about"><li className='hover:border-b-2 border-black cursor-pointer'>About us</li></a>
            <a href="/terms&privacy"><li className='hover:border-b-2 border-black cursor-pointer'>Terms & privacy</li></a>
            <a href="/review"><li className='hover:border-b-2 border-black cursor-pointer'>Reviews</li></a>
            <a href="/contact"><button className='hover:border-b-2 border-black cursor-pointer'>Contact</button></a>
            </ul>
           }
        </nav>
    </div>
  )
}

export default Navbar