import React from 'react'

const Footer = () => {
  return (
    <div className='bg-black text-white p-[30px] flex flex-col items-center justify-around text-lg'>
      <section>
        <footer className='flex items-start justify-around p-[20px] max-sm:flex max-sm:flex-col max-sm:gap-[40px] gap-7'>
          <ul className='flex flex-col gap-[5px]'>
            <p className='font-semibold text-[20px] hover:border-b-2'><a href="/">Strongbox Express</a></p>
            <a href="mailto:strongboxenterprise@yahoo.com"><li className='hover:border-b-2'>strongboxenterprise@yahoo.com</li></a>
            <a href="/about"><li className='hover:border-b-2'>About us</li></a>
            <a href="/review"><li className='hover:border-b-2'>Reviews</li></a>
            <li className='hover:border-b-2'><a href="/feedback">Feedback</a></li>
            <a href="/terms&privacy">
            <li className='hover:border-b-2'>Terms&privacy</li>
            </a>
          </ul>
          <div className='flex flex-row max-sm:justify-between gap-7 max-sm:w-[100%]'>
            <ul className='flex flex-col gap-[5px]'>
              <p className='font-semibold text-[20px]'>News Corp</p>
              <li className='hover:border-b-2'>The investors chronicle</li>
              <li className='hover:border-b-2'>The finacial News</li>
              <li className='hover:border-b-2'>Country life</li>
              <li className='hover:border-b-2'>City A.m</li>
              <li className='hover:border-b-2'>HL news</li>
              <li className='hover:border-b-2'>Telegraph property</li>
              <li className='hover:border-b-2'>REA Group</li>
            </ul>
            <ul className='flex flex-col gap-[5px]'>
              <p className='font-semibold text-[20px]'>News Corp</p>
              <li className='hover:border-b-2'>Storyful</li>
              <li className='hover:border-b-2'></li>
              <li className='hover:border-b-2'>Makaan.com</li>
              <li className='hover:border-b-2'>Housing.com</li>
              <li className='hover:border-b-2'>PropTiger.com</li>
              <li className='hover:border-b-2'>New Corp Australia</li>
              <li className='hover:border-b-2'>News uk</li>
            </ul>
          </div>
        </footer>
        <div className=' w-[100%] text-center justify-center align-middle'>
             ©2024 All rights reserved. | Developed by 
             <span className='underline'> Fantastic Hub</span>
        </div>
      </section>
    </div>
  )
}

export default Footer