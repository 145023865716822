import React from 'react'
import Navbar from '../landingpage/Navbar'
import Hero from '../landingpage/Hero'
import Browse from '../landingpage/Browse'
import Trends from '../landingpage/Trends'
import Footer from '../landingpage/Footer'
import Luxury from '../landingpage/Luxury'

const homepage = () => {
  return (
    <div>
        <Navbar/>
        <Hero/>
        <Browse/>
        <Luxury/>
        <Trends/>
        <Footer/>
    </div>
  )
}

export default homepage