// ReviewBox.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/pagination'; // Import Pagination styles
import 'swiper/css/navigation'; // Import Navigation styles

// Import Swiper core and required modules
import { Navigation, Pagination } from 'swiper/modules'; // Import modules correctly
import Navbar from './Navbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
const Review = () => {
    const reviews = [
        {
            text: "Strongbox Express helped me find my dream home! The team was professional and attentive.",
            author: "John Doe"
        },
        {
            text: "Great service and fantastic selection of properties. Highly recommend!",
            author: "Jane Smith"
        },
        {
            text: "I sold my car quickly and for a great price thanks to Strongbox Express.",
            author: "Michael Johnson"
        },
        {
            text: "A seamless experience from start to finish. Will use them again!",
            author: "Sarah Brown"
        },
        {
            text: "Strongbox made my home-buying experience so easy! The interface is user-friendly, and I found my dream home in no time.",
            author: "Olivia Martin"
        },
        {
            text: "I sold my property quickly and at a great price thanks to Strongbox. Their team was professional and supportive throughout the process.",
            author: "Ethan Thompson"
        },
        {
            text: "The property listings are detailed and accurate. I love how easy it is to filter by my preferences.",
            author: "Ava Robinson"
        },
        {
            text: "Strongbox helped me find an investment property that exceeded my expectations. I couldn't be happier!",
            author: "Liam Clark"
        },
        {
            text: "As a first-time buyer, I was nervous, but Strongbox's resources and support made everything manageable.",
            author: "Sophia Lee"
        },
        {
            text: "The customer service at Strongbox is top-notch! They were always available to answer my questions.",
            author: "Mason Hall"
        },
        {
            text: "I love the neighborhood insights provided with each listing. It helped me choose the right area for my family.",
            author: "Isabella Wright"
        },
        {
            text: "The website is visually appealing and easy to navigate. I highly recommend Strongbox to anyone looking to buy or sell property.",
            author: "James Young"
        },
        {
            text: "Strongbox connects buyers and sellers seamlessly. I was able to negotiate a great deal with minimal hassle.",
            author: "Charlotte King"
        },
        {
            text: "I appreciate the thoroughness of the property descriptions. I felt well-informed about my options.",
            author: "Benjamin Scott"
        }
    ];

    return (
        <div>
        <Helmet>
        <title>Our reviews - Strongbox Express</title>
        <meta name="description" content="Read customer reviews and testimonials
        about Strongbox Express and discover how we simplify property buying, selling, and management for our clients." />
      </Helmet>
            <Navbar/>
            <h1 className='flex items-center justify-center font-semibold text-[30px] pt-[40px]'>Reviews</h1>
           <section className='flex items-center justify-center p-[100px]'>
           <Swiper
                pagination={{ clickable: true }} // Enable pagination
                navigation // Enable navigation buttons
                modules={[Pagination, Navigation]} // Register modules
                loop // Enable loop mode
                className="h-48 max-sm:h-96" // Set height of the swiper
            >
              <div>
              {reviews.map((review, index) => (
                    <SwiperSlide key={index} className="flex flex-col justify-center items-center text-center p-4 shadow-lg">
                        <p className="text-lg italic">"{review.text}"</p>
                        <h4 className="mt-4 font-semibold text-gray-800">- {review.author}</h4>
                    </SwiperSlide>
                ))}
              </div>
            </Swiper>
           </section>
           <Footer/>
        </div>
    );
};

export default Review;
