import React from 'react'
import '../landingpage/style.css'
const Luxury = () => {
  return (
    <div>
        <section className='flex flex-col items-center justify-center p-[70px]'>
            <p className='text-[30px] font-bold '>Luxury Cars</p>
            <main className='grid grid-cols-3 max-sm:grid-cols-1 max-sm:items-center max-sm:justify-center max-sm:p-[10px]  p-[50px] justify-center gap-[70px] text-3xl'>
                <a href="/toyota">
                <div className='toy text-white flex items-center justify-center'>Toyota</div>
                </a>
                <a href="/nissan">
                <div className='nis text-white flex items-center justify-center'>Nissan</div>
                </a>
                <a href="/bmw">
                <div className='bmw text-white flex items-center justify-center'>BMW</div>
                </a>
                {/* row2 */}
                <a href="/ford">
                <div className='ford text-white flex items-center justify-center'>Ford</div>
                </a>
               <a href="/benz">
               <div className='benz text-white flex items-center justify-center'>Benz</div>
               </a>
                <a href="/lexus">
                <div className='lex text-white flex items-center justify-center'>Lexus</div>
                </a>
            </main>
        </section>
    </div>
  )
}

export default Luxury