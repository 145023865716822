import React from 'react'
import t1 from './img/l1.jpeg'
import t2 from './img/l2.jpg'
import t3 from './img/l3.jpg'
import t4 from './img/l4.jpg'
import t5 from './img/l5.jpg'
import t6 from './img/l6.jpg'
import t7 from './img/l7.jpg'
import t8 from './img/l8.jpg'
import t9 from './img/l9.jpg'
import t10 from './img/l10.jpg'
import t11 from './img/l12.jpg'
import t12 from './img/t12.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet'
import siteLandsImage from './img/l7.jpg'; 
const Houses = ({id, name, image, details, location, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: {amount}</p> 
    <p>location: {location}</p>
    </div>
    </div>
  </div>
); 
const house  = [
    {
        id: 1,
        name: "2-Acre Woodland Plot",
        image:`${t1}`,
        details: "Small woodland ideal for conservation and timber harvesting.",
        location: "South West England",
        price: "£25,000",
      },
      {
        id: 2,
        name: "1.5-Acre Agricultural Land",
        image:`${t2}`,
        details: "Suitable for small-scale farming, located near public transport.",
        location: "Kent",
        price: "£18,000",
      },
      {
        id: 3,
        name: "3-Acre Paddock",
        image:`${t3}`,
        details: "Perfect for equestrian use, with access to water and utilities.",
        location: "Cheshire",
        price: "£35,000",
      },
      {
        id: 4,
        name: "0.5-Acre Development Plot",
        image:`${t4}`,
        details: "Prime land for residential development, planning permission approved.",
        location: "Surrey",
        price: "£150,000",
      },
      {
        id: 5,
        name: "2.2-Acre Farmland",
        image:`${t5}`,
        details: "Fertile land for organic farming, comes with irrigation system.",
        location: "Norfolk",
        price: "£22,000",
      },
      {
        id: 6,
        name: "1-Acre Building Plot",
        image:`${t6}`,
        details: "Land with planning permission for a 4-bedroom house.",
        location: "Wales",
        price: "£100,000",
      },
      {
        id: 7,
        name: "1.7-Acre Grazing Land",
        image:`${t7}`,
        details: "Pasture land ideal for sheep grazing, with natural water source.",
        location: "Yorkshire",
        price: "£15,000",
      },
      {
        id: 8,
        name: "1-Acre Garden Allotment",
        image:`${t8}`,
        details: "Private allotment, perfect for vegetable growing.",
        location: "Birmingham",
        price: "£10,000",
      },
      {
        id: 9,
        name: "2.5-Acre Hobby Farm",
        image:`${t9}`,
        details: "Small farm setup with livestock pens and crop area.",
        location: "Devon",
        price: "£40,000",
      },
      {
        id: 10,
        name: "1.2-Acre Vineyard Plot",
        image:`${t10}`,
        details: "Land suited for small-scale vineyard, located in a wine region.",
        location: "Sussex",
        price: "£50,000",
      },
      {
        id: 11,
        name: "0.8-Acre Orchard",
        image:`${t11}`,
        details: "Apple orchard with potential for cider production.",
        location: "Herefordshire",
        price: "£20,000",
      },
      {
        id: 12,
        name: "1-Acre Pastureland",
        image:`${t12}`,
        details: "Good quality grassland, suitable for cattle grazing.",
        location: "Cumbria",
        price: "£12,000",
      }
]
const Land = () => {
  return (
    <div>
      <Helmet>
        <title>Site Lands - Strongbox Express</title>
        <meta name="description" content="Explore available land properties on Strongbox Express. Find and invest in prime real estate for residential, commercial, or agricultural purposes with ease." />

        {/* Open Graph meta tags */}
        <meta property="og:title" content="Site Lands - Strongbox Express" />
        <meta property="og:description" content="Explore available land properties on Strongbox Express. Find and invest in prime real estate for residential, commercial, or agricultural purposes with ease." />
        <meta property="og:image" content={siteLandsImage} />
        <meta property="og:url" content="https://www.strongboxexpress.com/lands" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Site Lands - Strongbox Express" />
        <meta name="twitter:description" content="Explore available land properties on Strongbox Express. Find and invest in prime real estate for residential, commercial, or agricultural purposes with ease." />
        <meta name="twitter:image" content={siteLandsImage} />
      </Helmet>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {house.map((allhouse) => (
          <Houses
            key={allhouse.id}
            id={allhouse.id}
            name={allhouse.name}
            image={allhouse.image}
            details={allhouse.details}
            amount={allhouse.price}
            location={allhouse.location}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Land