import React from 'react'
import img1 from './img/strongbox logo.jpeg'
const Trends = () => {
  return (
    <div className='flex flex-col items-center justify-center p-[70px]'>
        <p className='text-[30px] font-semibold'>Trends</p>
        <section className='grid grid-cols-2 max-sm:grid-cols-1  items-center justify-center gap-[24px]  pt-[50px]'>
            <div className='bg-white shadow-lg w-[30vw]  max-sm:w-[60vw] flex flex-col items-center justify-center'>
                <img className='rounded-t-md' width={350} src={img1} alt="" />
                <p className='bg-white p-[10px] w-[30vw] max-sm:w-[60vw] text-center'>Digital Transformation: We utilize advanced technology,
                     such as virtual tours and online listings, to streamline
                     the buying, selling, and renting processes, making them more accessible and efficient..</p>
            </div>
            <div className='bg-white shadow-lg w-[30vw]  max-sm:w-[60vw] flex flex-col items-center justify-center'>
                <img  className='rounded-t-md' width={350} src={img1} alt="" />
                <p className='bg-white p-[10px]  w-[30vw] max-sm:w-[60vw] text-center'>
                Sustainability: Eco-friendly properties and electric vehicles are gaining
                 traction. We focus on promoting sustainable
                 living options to meet the growing demand for green solutions.
                </p>
            </div>
            <div className='bg-white shadow-lg w-[30vw]  max-sm:w-[60vw] flex flex-col items-center justify-center'>
                <img  className='rounded-t-md' width={350} src={img1} alt="" />
                <p className='bg-white p-[10px] text-center'>
                Urban vs. Suburban Living: With changing lifestyles post-pandemic, we cater
                 to both urban dwellers seeking convenience and families wanting spacious suburban homes.
                </p>
            </div>
            <div className='bg-white shadow-lg w-[30vw]  max-sm:w-[60vw] flex flex-col items-center justify-center'>
                <img  className='rounded-t-md' width={350} src={img1} alt="" />
                <p className='bg-white p-[10px] text-center'>Short-Term Rentals: The popularity of short-term rentals is on the rise. We offer guidance
                     for investors interested in capitalizing on this lucrative market.</p>
            </div>
        </section>
    </div>
  )
}

export default Trends