import React from 'react'
import "./style.css"
const Hero = () => {
  return (
    <div>
      <section className='bg-hero flex items-center  justify-center p-[200px]'>
      <p className='text-white text-[80px] max-sm:text-[40px] w-[500vw]  text-center font-bold'>The #1 site Real Property professionals trust*</p>
      </section>
    </div>
  )
}

export default Hero   