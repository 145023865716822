import React from 'react'
import t1 from './img/new1.jpg'
import t2 from './img/new2.jpg'
import t3 from './img/new3.jpg'
import t5 from './img/new5.jpg'
import t6 from './img/open13.jpg'
import t7 from './img/open14.jpg'
import Footer from './Footer';
import Navbar from './Navbar'
const Houses = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>Upcoming</p>
    </div>
    </div>
  </div>
); 
const house  = [
    {
        id: 1,
        image: `${t1}`,
        name: "Green Valley Estate",
        details: "Sustainable 4-bedroom homes with solar panels and rainwater collection system.",
        amount: "750,000",
      },
      {
        id: 2,
        image: `${t2}`,
        name: "The Oaks Residences",
        details: "Luxury 5-bedroom homes with private gardens and smart home features.",
        amount: "1,100,000",
      },
      {
        id: 3,
        image: `${t3}`,
        name: "Harbor View Apartments",
        details: "Modern 2-3 bedroom apartments with stunning harbor views and rooftop terraces.",
        amount: "620,000",
      },
      {
        id: 4,
        image: `${t1}`,
        name: "Woodland Retreat",
        details: "Charming 3-bedroom wooden cabin set in a quiet forest with large windows for natural light.",
        amount: "450,000",
      },
      {
        id: 5,
        image: `${t5}`,
        name: "Meadow Park Homes",
        details: "Family-oriented 3-4 bedroom houses with nearby schools and parks.",
        amount: "680,000",
      },
      {
        id: 6,
        image: `${t6}`,
        name: "Hillside Terraces",
        details: "Eco-friendly 2-3 bedroom terraced homes with energy-efficient designs.",
        amount: "550,000",
      },
      {
        id: 7,
        image: `${t7}`,
        name: "Royal Gardens",
        details: "Exclusive gated community with 6-bedroom houses, private pools, and tennis courts.",
        amount: "2,500,000",
      }
]
const Newcon = () => {
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {house.map((allhouse) => (
          <Houses
            key={allhouse.id}
            id={allhouse.id}
            name={allhouse.name}
            image={allhouse.image}
            details={allhouse.details}
            amount={allhouse.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Newcon