import React from 'react'
import t1 from './img/toyota.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Toyotacars = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center  max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>65% sold</p>
    </div>
    </div>
  </div>
); 
const toyota  = [
  {
    id: 1,
    image: { t1 },
    name: "Toyota Camry",
    details: "A reliable mid-size sedan with excellent fuel efficiency.",
    amount: "15000",
  },
  {
    id: 2,
    image: { t1 },
    name: "Toyota Corolla",
    details: "Compact car known for its affordability and durability.",
    amount: "16000",
  },
  {
    id: 3,
    image: { t1 },
    name: "Toyota Highlander",
    details: "Spacious SUV perfect for family road trips and daily driving.",
    amount: "17000",
  },
  {
    id: 4,
    image: { t1 },
    name: "Toyota Prius",
    details: "Hybrid car with top-notch fuel economy and eco-friendly design.",
    amount: "18000",
  },
  {
    id: 5,
    image: { t1 },
    name: "Toyota Rav4",
    details: "Versatile compact SUV ideal for both city and adventure driving.",
    amount: "19000",
  },
  {
    id: 6,
    image: { t1 },
    name: "Toyota Avalon",
    details: "Premium full-size sedan offering luxury and performance.",
    amount: "20000",
  },
  {
    id: 7,
    image: { t1 },
    name: "Toyota Tacoma",
    details: "Durable mid-size pickup truck for off-road and hauling tasks.",
    amount: "21000",
  },
  {
    id: 8,
    image: { t1 },
    name: "Toyota 4Runner",
    details: "Rugged SUV built for off-roading and outdoor adventures.",
    amount: "22000",
  },
  {
    id: 9,
    image: { t1 },
    name: "Toyota Tundra",
    details: "Full-size pickup truck designed for tough jobs and towing.",
    amount: "23000",
  },
  {
    id: 10,
    image: { t1 },
    name: "Toyota Sequoia",
    details: "Large SUV with spacious seating and powerful V8 engine.",
    amount: "24000",
  },
  {
    id: 11,
    image: { t1 },
    name: "Toyota Sienna",
    details: "Family minivan with ample space and advanced safety features.",
    amount: "25000",
  },
  {
    id: 12,
    image: { t1 },
    name: "Toyota Supra",
    details: "High-performance sports car with a sleek and stylish design.",
    amount: "26000",
  },
  {
    id: 13,
    image: { t1 },
    name: "Toyota C-HR",
    details: "Subcompact crossover with a bold design and agile handling.",
    amount: "27000",
  },
  {
    id: 14,
    image: { t1 },
    name: "Toyota Land Cruiser",
    details: "Legendary SUV known for its off-road capability and durability.",
    amount: "28000",
  },
  {
    id: 15,
    image: { t1 },
    name: "Toyota Venza",
    details: "Stylish mid-size crossover offering comfort and efficiency.",
    amount: "29000",
  },
  {
    id: 16,
    image: { t1 },
    name: "Toyota Yaris",
    details: "Affordable subcompact car with great fuel efficiency.",
    amount: "14000",
  },
  {
    id: 17,
    image: { t1 },
    name: "Toyota GR86",
    details: "Lightweight sports coupe designed for an exhilarating drive.",
    amount: "30000",
  },
  {
    id: 18,
    image: { t1 },
    name: "Toyota Mirai",
    details: "Innovative hydrogen fuel-cell sedan with zero emissions.",
    amount: "31000",
  },
  {
    id: 19,
    image: { t1 },
    name: "Toyota Fortuner",
    details: "Mid-size SUV offering power and off-road capabilities.",
    amount: "32000",
  },
  {
    id: 20,
    image: { t1 },
    name: "Toyota Hilux",
    details: "Rugged pickup truck designed for tough terrains and heavy-duty use.",
    amount: "33000",
  },
  {
    id: 21,
    image: { t1 },
    name: "Toyota Urban Cruiser",
    details: "Compact SUV built for urban driving with a sporty design.",
    amount: "34000",
  },
  {
    id: 22,
    image: { t1 },
    name: "Toyota Vios",
    details: "Compact sedan offering a comfortable and smooth ride.",
    amount: "15000",
  },
  {
    id: 23,
    image: { t1 },
    name: "Toyota FJ Cruiser",
    details: "Retro-styled SUV known for its off-road prowess and durability.",
    amount: "35000",
  },
  {
    id: 24,
    image: { t1 },
    name: "Toyota ProAce",
    details: "Versatile van ideal for businesses and large cargo needs.",
    amount: "36000",
  },
  {
    id: 25,
    image: { t1 },
    name: "Toyota Innova",
    details: "Multi-purpose vehicle offering ample seating and comfort.",
    amount: "37000",
  }
]
const Toylist = () => {
 
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {toyota.map((alltoyota) => (
          <Toyotacars
            key={alltoyota.id}
            id={alltoyota.id}
            name={alltoyota.name}
            image={alltoyota.image.t1}
            details={alltoyota.details}
            amount={alltoyota.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Toylist