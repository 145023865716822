import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Helmet } from 'react-helmet';  
const About = () => {
  return (
    <div>
       <Helmet>
        <title>About Us - Strongbox Express</title>
        <meta name="description" content="Learn more about Strongbox Express and our mission to simplify property buying, selling, and management." />
      </Helmet>
        <Navbar/>
        <section className='flex flex-col items-center justify-center p-[50px] text-lg gap-11'>
            <div className='flex flex-col gap-4'>
            <h2 className='text-2xl'>About Strongbox Express</h2>
            <p className='w-[50vw] max-sm:w-[80vw]'>Welcome to Strongbox Express, your premier destination for all your property needs! 
            We are dedicated to simplifying the process of buying, selling, and renting properties,
             vehicles, and more. Our mission is to provide a seamless and efficient experience for our clients, 
            whether you are looking to invest in your dream home, sell your property for the best price, or find the perfect rental.</p>
            </div>
            <div>
           <h2  className='text-2xl'>Our Vision</h2>
           <p  className='w-[50vw] max-sm:w-[80vw]'>
           At Strongbox Express, we envision a world where real estate transactions are straightforward,
             transparent, and stress-free. We strive to empower our clients with the knowledge and resources 
             they need to make informed decisions. Our commitment to integrity and excellence drives us to be 
             the best in the industry.
           </p>
            </div>
            <div className='flex flex-col items-center justify-center p-[50px] text-lg gap-11'>
            <ol className='list-decimal flex flex-col gap-[10px]'>
            <h2 className='text-2xl'>What We Offer</h2>
             <li>Property Buying and Selling:</li>
            <p className='w-[50vw] max-sm:w-[80vw]'> We specialize in connecting buyers with their ideal properties, ranging from cozy apartments to luxurious estates. Our expert team is dedicated to understanding your needs and guiding you through every step of the buying or selling process.</p>
            <li>Rentals:</li>
            <p className='w-[50vw] max-sm:w-[80vw]'>Looking for a place to call home? Strongbox Express offers a wide selection of rental properties to suit every budget and lifestyle. Our platform makes it easy to find the perfect rental that meets your criteria.</p>
            <li>3Automotive Sales and Rentals:</li>
            <p className='w-[50vw] max-sm:w-[80vw]'>Beyond real estate, we also provide services for buying, selling, and renting cars. Whether you’re looking for a new vehicle or a temporary rental, we have you covered.</p>
             <li>Comprehensive Support:</li>
             <p className='w-[50vw] max-sm:w-[80vw]'>Our experienced team of professionals is here to assist you at every turn. From initial consultations to negotiations and closing, we ensure that you have the support you need to make the best choices.</p>
            </ol>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default About