import React from 'react'
import t1 from './img/benz.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
const Cars = ({id, name, image, details, amount}) => (
  <div key={id} className='flex flex-col items-center justify-center text-lg bg-white shadow-xl'>
    <img className='rounded-t-lg' width={350} src={image} alt={details} />
    <div className='flex flex-col gap-[15px] items-center justify-center rounded-b-lg p-[10px]'>
    <h1 className='font-semibold text-[20px]'>{name}</h1>
    <h2 className='w-[25vw] text-center max-sm:w-[70vw]'>{details}</h2>
    <div className='flex items-center justify-center gap-[80px] font-semibold'>
    <p>Amount: £{amount}</p> 
    <p>40% sold</p>
    </div>
    </div>
  </div>
); 
const car  = [
    {
        id: 1,
        image: {t1},
        name: "Mercedes-Benz C-Class",
        details: "A luxury compact sedan offering a smooth ride and advanced technology.",
        amount: "45000",
      },
      {
        id: 2,
        image: {t1},
        name: "Mercedes-Benz E-Class",
        details: "A mid-size luxury sedan with premium features and cutting-edge safety technology.",
        amount: "55000",
      },
      {
        id: 3,
        image: {t1},
        name: "Mercedes-Benz S-Class",
        details: "The flagship luxury sedan offering ultimate comfort, performance, and innovation.",
        amount: "110000",
      },
      {
        id: 4,
        image: {t1},
        name: "Mercedes-Benz GLC",
        details: "A compact luxury SUV with a refined interior and versatile performance.",
        amount: "51000",
      },
      {
        id: 5,
        image: {t1},
        name: "Mercedes-Benz GLE",
        details: "A mid-size luxury SUV with advanced technology and spacious seating.",
        amount: "70000",
      },
      {
        id: 6,
        image: {t1},
        name: "Mercedes-Benz GLS",
        details: "A full-size luxury SUV offering three-row seating and powerful performance.",
        amount: "93000",
      },
    
]
const Benz = () => {
 
  return (
    <div>
      <Navbar/>
      <section className='p-[70px] flex-wrap flex items-center justify-center gap-12'>
        {car.map((allcar) => (
          <Cars
            key={allcar.id}
            id={allcar.id}
            name={allcar.name}
            image={allcar.image.t1}
            details={allcar.details}
            amount={allcar.amount}
          />
        ))}
      </section>
      <Footer/>
    </div>
  )
}

export default Benz